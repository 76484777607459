import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { GuestAddBankAccount } from "@portal-guest/components/GuestAddBankAccount/GuestAddBankAccount";
import { GuestAddPaymentCard } from "@portal-guest/components/GuestAddPaymentCard/GuestAddPaymentCard";
import { GuestAndQuickPayPaymentAmountFields } from "@portal-guest/components/GuestAndQuickPayPaymentAmountFields/GuestAndQuickPayPaymentAmountFields";
import {
  StyledCircularProgress,
  StyledForm,
  StyledPaymentButtons,
  StyledPaymentMethodContainer,
} from "@portal-guest/components/QuickPayForm/QuickPayForm.styled";
import { useQuickPayAddPaymentMethodMutation } from "@portal-guest/mutations/useQuickPayAddPaymentMethodMutation";
import { useQuickPayCreatePaymentMutation } from "@portal-guest/mutations/useQuickPayCreatePaymentMutation";
import { useQuickPayFetchPaymentMethodQuery } from "@portal-guest/queries/useQuickPayFetchPaymentMethodQuery";
import { useQuickPayPaymentMethodsQuery } from "@portal-guest/queries/useQuickPayPaymentMethodsQuery";
import { quickPayConfirmationPath } from "@portal-guest/routes/routePaths";
import { GuestAndQuickPayFormValues } from "@portal-guest/types/GuestAndQuickPayTypes";
import { PaymentMethodOptionsType } from "@portal-shared/components/PaymentMethodOptions/PaymentMethodOptions";
import { PaymentMethodRadioInput } from "@portal-shared/components/PaymentMethodRadioInput/PaymentMethodRadioInput";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

interface QuickPayFormProps {
  isNoCheckOrAch: boolean;
  isNoCreditOrDebitCard: boolean;
  pastDueBalance: string;
  paymentMethods: PaymentMethod[];
  paymentMethodsIsFetching: boolean;
  token: string;
  totalBalance: string;
}

const getPaymentAmount = (data: GuestAndQuickPayFormValues) => {
  const { paymentType: submittedPaymentType } = data;

  if (submittedPaymentType === "total") {
    return data.totalBalance;
  }
  if (submittedPaymentType === "pastDue") {
    return data.pastDueBalance;
  }
  if (submittedPaymentType === "custom") {
    return data.customAmount;
  }
  throw new Error(
    "Unable to determine the One click Quick Pay payment amount from the form"
  );
};

const Form = (props: QuickPayFormProps) => {
  const {
    isNoCheckOrAch,
    isNoCreditOrDebitCard,
    paymentMethods,
    token,
    totalBalance,
    pastDueBalance,
    paymentMethodsIsFetching,
  } = props;
  const flash = useRhFlash();
  const navigate = useNavigate();

  const { t } = useRhIntl();

  const [oneTimePaymentMethodId, setOneTimePaymentMethodId] =
    useState<string>();

  const { data: oneTimePaymentMethod, refetch: refetchPaymentMethod } =
    useQuickPayFetchPaymentMethodQuery({
      enabled: false,
      paymentMethodId: oneTimePaymentMethodId || "",
      token,
    });

  const addPaymentMethodMutation = useQuickPayAddPaymentMethodMutation(token);
  const createPaymentMutation = useQuickPayCreatePaymentMutation();

  const defaultPayment =
    paymentMethods.find((method) => method.defaultPaymentMethod === true) ??
    paymentMethods?.[0];

  const formMethods = useForm<GuestAndQuickPayFormValues>({
    defaultValues: {
      customAmount: "",
      pastDueBalance,
      paymentType: "total",
      selectedPayment: defaultPayment?.id,
      totalBalance,
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { isValid },
    getValues,
    setValue,
  } = formMethods;

  const selectedPaymentMethod = watch("selectedPayment");

  watch("customAmount");
  watch("paymentType");

  useEffect(() => {
    if (oneTimePaymentMethodId) {
      refetchPaymentMethod();
    }
  }, [oneTimePaymentMethodId, refetchPaymentMethod]);

  const handleZuoraResponseSuccess = (
    data: ZuoraAddPaymentResponseType,
    paymentMethodOptions?: PaymentMethodOptionsType
  ) => {
    addPaymentMethodMutation.mutate(
      {
        paymentMethodId: data.refId,
        ...paymentMethodOptions,
        token,
      },
      {
        onError: () => {
          flash.error(t("QuickPayForm.addPaymentMethodError"));
        },
        onSuccess: () => {
          flash.success(t("QuickPayForm.paymentAdded"));
          if (paymentMethodOptions?.isOneTimePayment) {
            setOneTimePaymentMethodId(data.refId);
          }
          setValue("selectedPayment", data.refId);
        },
      }
    );
  };

  const onSubmit = handleSubmit((data) => {
    const amount = getPaymentAmount(data);

    createPaymentMutation.mutate(
      {
        amount,
        isOneTimePayment: data.selectedPayment === oneTimePaymentMethodId,
        paymentMethodId: data.selectedPayment,
        token,
      },
      {
        onError: (error) => {
          flash.error(t("QuickPayForm.paymentError"));
        },
        onSuccess: (response) => {
          navigate(quickPayConfirmationPath(), {
            state: {
              confirmationNumber: response.paymentNumber,
              isDisconnected: response.isPremiseDisconnected,
              remainingBalance: response.remainingBalance,
            },
          });
        },
      }
    );
  });

  const amount = Number(getPaymentAmount(getValues()));

  const submitDisabled =
    !isValid ||
    createPaymentMutation.isPending ||
    !selectedPaymentMethod ||
    amount < 1;

  return (
    <FormProvider {...formMethods}>
      <StyledForm onSubmit={onSubmit}>
        <GuestAndQuickPayPaymentAmountFields />
        {paymentMethodsIsFetching ? (
          <RhCircularProgress />
        ) : (
          <>
            <StyledPaymentMethodContainer>
              <RhTypography variant="subtitle1" component="legend">
                {t("QuickPayForm.payFrom")}
              </RhTypography>
              {paymentMethods.map((paymentMethod) => (
                <PaymentMethodRadioInput
                  key={paymentMethod.id}
                  paymentMethod={paymentMethod}
                />
              ))}
              {oneTimePaymentMethod && (
                <PaymentMethodRadioInput
                  key={oneTimePaymentMethod.id}
                  paymentMethod={oneTimePaymentMethod}
                  isOneTimePayment
                />
              )}
            </StyledPaymentMethodContainer>
            <StyledPaymentButtons>
              {!isNoCreditOrDebitCard && (
                <GuestAddPaymentCard
                  onCardAdded={handleZuoraResponseSuccess}
                  showPaymentOptions
                  showFlashSuccess={false}
                  isFirstPaymentMethod={paymentMethods.length === 0}
                />
              )}
              {!isNoCheckOrAch && (
                <GuestAddBankAccount
                  onBankAccountAdded={handleZuoraResponseSuccess}
                  showPaymentOptions
                  showFlashSuccess={false}
                  isFirstPaymentMethod={paymentMethods.length === 0}
                />
              )}
            </StyledPaymentButtons>
          </>
        )}

        <RhButton
          data-tracking-click={{ event: "Customer sent quick pay payment" }}
          type="submit"
          color="primary"
          disabled={submitDisabled}
          fullWidth
        >
          {createPaymentMutation.isPending ? (
            <>
              <StyledCircularProgress color="inherit" size={14} />
              {t("QuickPayForm.submittingPayment")}
            </>
          ) : (
            t("QuickPayForm.submitPayment")
          )}
        </RhButton>
      </StyledForm>
    </FormProvider>
  );
};

export const QuickPayForm = (
  props: Omit<QuickPayFormProps, "paymentMethods" | "paymentMethodsIsFetching">
) => {
  const {
    token,
    totalBalance,
    pastDueBalance,
    isNoCheckOrAch,
    isNoCreditOrDebitCard,
  } = props;
  const flash = useRhFlash();

  const { t } = useRhIntl();
  const quickPayPaymentMethodsQuery = useQuickPayPaymentMethodsQuery({ token });

  useEffect(() => {
    if (quickPayPaymentMethodsQuery.error) {
      flash.error(t("QuickPayForm.paymentMethodError"));
    }
  }, [quickPayPaymentMethodsQuery.error]);

  if (quickPayPaymentMethodsQuery.isPending) {
    return <RhCircularProgress />;
  }

  if (quickPayPaymentMethodsQuery.isError) {
    return null;
  }

  return (
    <Form
      pastDueBalance={pastDueBalance}
      totalBalance={totalBalance}
      token={token}
      paymentMethods={quickPayPaymentMethodsQuery.data.paymentMethods}
      paymentMethodsIsFetching={quickPayPaymentMethodsQuery.isFetching}
      isNoCheckOrAch={isNoCheckOrAch}
      isNoCreditOrDebitCard={isNoCreditOrDebitCard}
    />
  );
};
