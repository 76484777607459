import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { createPasswordPageTranslations } from "@portal-account/pages/CreatePasswordPage/CreatePasswordPage.en.i18n";
import {
  StyledCreatePasswordPurpleSection,
  StyledCreatePasswordPurpleSectionCustomerInfoContainer,
  StyledCreatePasswordPurpleSectionSubtitle,
  StyledCreatePasswordPurpleSectionText,
  StyledCreatePasswordPurpleSectionTextContainer,
} from "@portal-account/pages/CreatePasswordPage/CreatePasswordPage.styled";
import { CreatePasswordViaOktaForm } from "@portal-account/pages/CreatePasswordPage/CreatePasswordViaOktaForm";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutPageLayout } from "@portal-shared/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { SESSION_STORAGE_CUSTOMER_KEY } from "@portal-shared/constants/customer.constant";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { ReactComponent as HighFives } from "@portal-shared/images/HighFives.svg";
import { Error500Page } from "@portal-shared/pages/Error500Page/Error500Page";
import { CustomerSessionStorageType } from "@portal-shared/types/customerTypes";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";

export const CreatePasswordPage = () => {
  const [searchParams] = useSearchParams();
  const activationToken = searchParams.get("activationToken");
  const [customerFromStorage] =
    useSessionStorage<CustomerSessionStorageType | null>(
      SESSION_STORAGE_CUSTOMER_KEY,
      null
    );

  const { translate, translateJsx } = useTranslations();

  if (!activationToken) {
    return <Error500Page />;
  }
  const {
    tCreatePasswordPageTwoFriendsHighFiving,
    tCreatePasswordPageCreateYourPassword,
    tCreatePasswordPageStayInformed,
    tCreatePasswordPageSitTight,
  } = translate(createPasswordPageTranslations);

  const {
    tCreatePasswordPageThanks,
    tCreatePasswordPageAccountNumber,
    tCreatePasswordPageRequestedService,
  } = translateJsx({
    tCreatePasswordPageAccountNumber: {
      accountNumber: customerFromStorage?.accountNumber,
    },
    tCreatePasswordPageRequestedService: {
      startDate: formatMonthDayYear(customerFromStorage?.serviceStartDate),
    },
    tCreatePasswordPageThanks: { firstName: customerFromStorage?.firstName },
  });

  const showPurpleSection =
    customerFromStorage &&
    customerFromStorage.accountNumber &&
    customerFromStorage.firstName &&
    customerFromStorage.serviceStartDate;

  return (
    <LoggedOutPageLayout>
      {showPurpleSection ? (
        <StyledCreatePasswordPurpleSection>
          <HighFives title={tCreatePasswordPageTwoFriendsHighFiving} />
          <StyledCreatePasswordPurpleSectionTextContainer>
            <RhTypography variant="h3" fontWeight="Bold">
              {tCreatePasswordPageThanks}
            </RhTypography>
            <StyledCreatePasswordPurpleSectionSubtitle>
              {tCreatePasswordPageSitTight}
            </StyledCreatePasswordPurpleSectionSubtitle>
            <StyledCreatePasswordPurpleSectionCustomerInfoContainer>
              <StyledCreatePasswordPurpleSectionText>
                {tCreatePasswordPageAccountNumber}
              </StyledCreatePasswordPurpleSectionText>
              <StyledCreatePasswordPurpleSectionText>
                {tCreatePasswordPageRequestedService}
              </StyledCreatePasswordPurpleSectionText>
            </StyledCreatePasswordPurpleSectionCustomerInfoContainer>
          </StyledCreatePasswordPurpleSectionTextContainer>
        </StyledCreatePasswordPurpleSection>
      ) : null}
      <LoggedOutPageHeader
        headerText={tCreatePasswordPageCreateYourPassword}
        subHeaderText={tCreatePasswordPageStayInformed}
      />
      <CreatePasswordViaOktaForm activationToken={activationToken} />
    </LoggedOutPageLayout>
  );
};
