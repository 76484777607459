import { EnvTypes } from "@common/enums/envTypes.enum";
import { getEnv } from "@common/services/getEnvApplicationSettings.service";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";

export interface CookieSetOptions {
  domain: string;
  expires?: Date;
  httpOnly?: boolean;
  maxAge?: number;
  partitioned?: boolean;
}

export const getCookieNameWithEnv = <T extends object>(
  cookieName: keyof T,
  defaultCookiesWithEnvValues: T
) => {
  const env = getEnv();

  if (!Object.keys(defaultCookiesWithEnvValues).includes(String(cookieName))) {
    return cookieName;
  }

  let cookieSuffix = "";

  switch (env) {
    case EnvTypes.local: {
      cookieSuffix = "-local";
      break;
    }
    case EnvTypes.localStaging:
    case EnvTypes.staging: {
      cookieSuffix = "-staging";
      break;
    }
    default: {
      cookieSuffix = "";
      break;
    }
  }
  const finalCookieName = `${String(cookieName)}${cookieSuffix}`;

  return finalCookieName;
};

// Only used for any cookie use in th logged in experience
export const useAppCookies = <T extends object>(
  defaultCookiesValues: T,
  defaultCookiesWithEnvValues: T = {} as T
) => {
  type KeyOfT = keyof T;

  const [documentCookies, documentSetCookie, documentRemoveCookie] =
    useCookies();

  const [cookies, setCookies] = useState<T>({} as T);
  const [cookiesLoading, setCookiesLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!cookiesLoading) {
      return;
    }

    if (Object.keys(cookies).length !== 0) {
      setCookiesLoading(false);
    }
  }, [cookies]);

  useEffect(() => {
    // Get only the cookies from cookies passed, ignore any others that happen to be in document.cookie
    const newCookies = Object.entries(defaultCookiesValues).reduce<T>(
      (acc, current) => {
        const [cookieName, defaultCookieValue] = current;

        const cookieNameWithEnv = getCookieNameWithEnv<T>(
          cookieName as keyof T,
          defaultCookiesWithEnvValues
        );

        const cookieValue =
          documentCookies[cookieNameWithEnv as keyof typeof documentCookies];

        return {
          ...acc,
          [cookieName]: cookieValue ?? defaultCookieValue,
        };
      },
      { ...defaultCookiesValues }
    );

    setCookies(newCookies);
  }, [defaultCookiesValues, defaultCookiesWithEnvValues, documentCookies]);

  const setCookie = useCallback(
    (cookieName: KeyOfT, cookieValue: T[KeyOfT], options: CookieSetOptions) => {
      const finalCookieName = getCookieNameWithEnv(
        cookieName,
        defaultCookiesWithEnvValues
      );

      documentSetCookie(String(finalCookieName), cookieValue, {
        ...options,
        path: "/",
        sameSite: "lax",
        secure: true,
      });
    },
    [defaultCookiesWithEnvValues, documentSetCookie]
  );

  type RemoveCookieType = typeof documentRemoveCookie;

  const removeCookie = useCallback(
    (cookieName: keyof T, options?: Parameters<RemoveCookieType>[1]) => {
      const finalCookieName = getCookieNameWithEnv(
        cookieName,
        defaultCookiesWithEnvValues
      );

      documentRemoveCookie(String(finalCookieName), options);
    },
    [defaultCookiesWithEnvValues, documentRemoveCookie]
  );

  return useMemo(
    () => ({
      cookies,
      cookiesLoading,
      removeCookie,
      setCookie,
    }),
    [cookies, cookiesLoading, removeCookie, setCookie]
  );
};
