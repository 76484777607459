import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { customerPreferencesCleared } from "@portal-account/slices/customerPreferencesSlice";
import { rewardsClear } from "@portal-account/slices/rewardsSlice";
import { usePortalSharedCookies } from "@portal-shared/hooks/usePortalSharedCookies";
import { useLogoutMutation } from "@portal-shared/mutations/useLogoutMutation";
import { rootPath } from "@portal-shared/routes/routePaths";
import { portalAuthClient } from "@portal-shared/services/PortalAuthClient.service";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const SignOutPage = () => {
  const dispatch = useDispatch();
  const { featureFlagClient } = useFeatureFlagClient();
  const navigate = useNavigate();
  const { portalAccountOktaBackendProxy } = featureFlagClient.getFlags([
    ["portalAccountOktaBackendProxy", false],
  ]);

  const {
    cookiesLoading,
    cookies: { portal_session_state: portalSessionState },
  } = usePortalSharedCookies();

  useEffect(() => {
    if (cookiesLoading) {
      return;
    }

    if (portalSessionState !== "logged_in") {
      sessionStorage.clear();
      dispatch(customerPreferencesCleared());
      dispatch(rewardsClear());
      navigate(rootPath());
    }
  }, [cookiesLoading, dispatch, navigate, portalSessionState]);

  const isFetchingFlags = featureFlagClient.isFetchingFlags();

  const logoutMutation = useLogoutMutation();

  useEffect(() => {
    if (isFetchingFlags || logoutMutation.isPending) {
      return;
    }

    if (portalAccountOktaBackendProxy.value) {
      logoutMutation.mutate();

      return;
    }

    portalAuthClient.signOut().then(() => {
      sessionStorage.clear();
      dispatch(customerPreferencesCleared());
      dispatch(rewardsClear());
    });
  }, [portalAccountOktaBackendProxy.value, isFetchingFlags]);

  return null;
};
