import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import {
  PortalPageLayoutStyledPageBackground,
  PortalPageLayoutStyledTandemBikeLeft,
  PortalPageLayoutStyledTandemBikeRight,
} from "@portal-shared/styled/portalPageLayout.styled";
import React from "react";

export const EnrollOffersPageBackground = () => {
  const { pageOffersBackgroundImageLeft, pageOffersBackgroundImageRight } =
    useBrandImageUrls();

  return (
    <>
      <PortalPageLayoutStyledTandemBikeLeft>
        <img
          src={pageOffersBackgroundImageLeft}
          alt=""
          width="195px"
          height="auto"
        />
      </PortalPageLayoutStyledTandemBikeLeft>
      <PortalPageLayoutStyledTandemBikeRight>
        <img
          src={pageOffersBackgroundImageRight}
          alt=""
          width="195px"
          height="auto"
        />
      </PortalPageLayoutStyledTandemBikeRight>
      <PortalPageLayoutStyledPageBackground />
    </>
  );
};
