export const loginFormTranslations: Record<string, string> = {
  tLoginFormEmail: "Email",
  tLoginFormErrorInvalidEmailOrPassword: "Invalid email or password.",
  tLoginFormErrorInvalidInputEmail: "Invalid email.",
  tLoginFormErrorInvalidInputEmailRequired: "Email is required.",
  tLoginFormErrorInvalidInputPasswordRequired: "Password is required.",
  tLoginFormHidePassword: "Hide password",
  tLoginFormLoggingInCustomer: "Logging you in",
  tLoginFormPassword: "Password",
  tLoginFormShowPassword: "Show password",
  tLoginFormSubmitCta: "Log In",
};
