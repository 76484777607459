import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { offerSnapshotCardTranslations } from "@portal-enroll/components/OfferSnapshotCard/OfferSnapshotCard.en.i18n";
import {
  CalculateBillButton,
  CalculateBillText,
  Card,
  CardCallout,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  CompareRatesButton,
  CompareRatesText,
  DesktopDivider,
  Features,
  FooterActionsContainer,
  PhoneLink,
} from "@portal-enroll/components/OfferSnapshotCard/OfferSnapshotCard.styled";
import { OfferFeatures } from "@portal-shared/components/OfferFeatures/OfferFeatures";
import { OfferRateAndEstimate } from "@portal-shared/components/OfferRateAndEstimate/OfferRateAndEstimate";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React from "react";

interface OfferSnapshotCardProps {
  offerSnapshot: OfferSnapshot;
  onCalculateBillClick?: () => void;
  onCompareRatesClick?: (offerSnapshot: OfferSnapshot) => void;
  onEnrollClick: () => void;
  onLearnMoreClick: () => void;
  phoneNumber?: string;
}
export const OfferSnapshotCard = (props: OfferSnapshotCardProps) => {
  const {
    offerSnapshot,
    onLearnMoreClick,
    onCalculateBillClick,
    onCompareRatesClick,
    onEnrollClick,
    phoneNumber = brandInformation.phoneNumbersGeneralSales,
  } = props;

  const brandImageUrls = useBrandImageUrls();

  const { translate } = useTranslations();

  const {
    tOfferSnapshotCardEnrollNow,
    tOfferSnapshotCardLearnMore,
    tOfferSnapshotCardCalculateMonthlyBill,
    tOfferSnapshotCardCompareToOthers,
  } = translate(offerSnapshotCardTranslations);

  const [locale] = useLocaleAtom();

  const showCallout = offerSnapshot.hasCallout();

  return (
    <Card
      $hasCallout={offerSnapshot.hasCallout()}
      $calloutBackgroundColor={offerSnapshot.calloutBackgroundColor()}
    >
      {showCallout ? (
        <CardCallout $backgroundColor={offerSnapshot.calloutBackgroundColor()}>
          <RhTypography variant="body1" fontWeight={FontWeight.Semibold}>
            {offerSnapshot.calloutText(locale)}
          </RhTypography>
        </CardCallout>
      ) : null}

      <CardHeader>
        <CardTitle variant="h3">{offerSnapshot.title}</CardTitle>

        <CardDescription variant="body1" color="textSecondary">
          {offerSnapshot.description(locale)}
        </CardDescription>
      </CardHeader>

      <DesktopDivider light />

      <Features>
        <OfferFeatures offerSnapshot={offerSnapshot} />
      </Features>

      <DesktopDivider light />

      <CardContent>
        <OfferRateAndEstimate offerSnapshot={offerSnapshot} />

        {onCalculateBillClick ? (
          <CalculateBillButton
            data-tracking-click={{
              event: "Offer Snapshot Card Calculate Bill Clicked",
              offerSnapshotUuid: offerSnapshot.uuid,
            }}
            onClick={onCalculateBillClick}
          >
            <img
              src={brandImageUrls.iconCalculator}
              alt="calculator"
              aria-hidden
            />
            <CalculateBillText variant="body3" component="span">
              {tOfferSnapshotCardCalculateMonthlyBill}
            </CalculateBillText>
          </CalculateBillButton>
        ) : null}

        {onCompareRatesClick ? (
          <CompareRatesButton
            data-tracking-click={{
              event: "Offer Snapshot Card Compare Rates Clicked",
              offerSnapshotUuid: offerSnapshot.uuid,
            }}
            onClick={() => onCompareRatesClick(offerSnapshot)}
          >
            <img src={brandImageUrls.iconScale} alt="scale" aria-hidden />
            <CompareRatesText>
              {tOfferSnapshotCardCompareToOthers}
            </CompareRatesText>
          </CompareRatesButton>
        ) : null}
      </CardContent>
      <RhDivider light />
      <CardFooter>
        <FooterActionsContainer>
          <RhButton
            data-tracking-click={{
              event: "Offer Snapshot Card Learn More Clicked",
              offerSnapshotUuid: offerSnapshot.uuid,
            }}
            color="primary"
            size="small"
            variant="outlined"
            onClick={onLearnMoreClick}
          >
            {tOfferSnapshotCardLearnMore}
          </RhButton>

          <RhButton
            data-tracking-click={{
              event: "Offer Snapshot Card Select Plan Clicked",
              offerSnapshotUuid: offerSnapshot.uuid,
            }}
            color="primary"
            size="small"
            onClick={onEnrollClick}
          >
            {tOfferSnapshotCardEnrollNow}
          </RhButton>
        </FooterActionsContainer>

        <PhoneLink
          href={`tel:${phoneNumber}`}
          data-tracking-click={{
            event: "Offer Snapshot Card Phone Number Clicked",
            offerSnapshotUuid: offerSnapshot.uuid,
          }}
        >
          <img src={brandImageUrls.iconPhone} alt="phone" aria-hidden />
          <RhTypography variant="body1" fontWeight={FontWeight.Regular}>
            {phoneNumber}
          </RhTypography>
        </PhoneLink>
      </CardFooter>
    </Card>
  );
};
