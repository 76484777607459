import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

export const LoginFormForm = styled.form`
  min-width: 250px;
`;

export const LoginFormCircularProgress = styled(CircularProgress)`
  margin-right: ${rhSpacingPx(1)};
`;

export const LoginFormCtaButton = styled(RhButton)`
  &.MuiButton-root {
    height: 50px;
    margin-bottom: ${rhSpacingPx(3)};
    width: 100%;
  }
`;

export const LoginFormInputLabel = styled.label`
  color: ${CSSVariables.COLOR_GREY_600};
  display: block;
  font-size: 12px;
  font-weight: ${FontWeight.Bold};
  padding-bottom: 0.25rem;
`;

interface LoginFormInputProps {
  $isError?: boolean;
}
export const LoginFormInput = styled.input<LoginFormInputProps>`
  border: 1px solid
    ${(props) =>
      props.$isError
        ? CSSVariables.COLOR_ERROR_MAIN
        : CSSVariables.COLOR_GREY_200};
  border-radius: 4px;
  font-size: 16px;
  height: 45px;
  padding: ${rhSpacingPx(1)} ${rhSpacingPx(1.5)};
  width: 100%;
  &:focus {
    border-color: transparent;
    outline: 2px solid
      ${(props) =>
        props.$isError
          ? CSSVariables.COLOR_ERROR_MAIN
          : CSSVariables.COLOR_PRIMARY_MAIN};
  }
`;

export const LoginFormInputErrorMessage = styled.div`
  color: ${CSSVariables.COLOR_ERROR_MAIN};
  height: ${rhSpacingPx(2)};
  margin-bottom: ${rhSpacingPx(1)};
  margin-top: ${rhSpacingPx(0.5)};
`;

export const LoginFormPasswordContainer = styled.div`
  position: relative;
  > svg {
    color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 2px solid ${CSSVariables.COLOR_PRIMARY_MAIN};
      outline-offset: 2px;
    }
  }
`;
