import { HttpStatusCodes } from "@common/types/apiResponseTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { CustomerContext } from "@portal-account/components/CustomerProvider/CustomerContext";
import { SESSION_STORAGE_CUSTOMER_ID_KEY } from "@portal-account/components/CustomerProvider/CustomerProvider.constants";
import { useCustomerQuery } from "@portal-account/queries/useCustomerQuery";
import { featureFlagUserIdAtom } from "@portal-shared/hooks/useFeatureFlagUserId/featureFlagUserId.atoms";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { Error500Page } from "@portal-shared/pages/Error500Page/Error500Page";
import { signOutPath } from "@portal-shared/routes/routePaths";
import { useSetAtom } from "jotai";
import React, { PropsWithChildren, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface CustomerProviderProps {}

export const CustomerProvider = ({
  children,
}: PropsWithChildren<CustomerProviderProps>) => {
  const navigate = useNavigate();
  const setFeatureFlagUserIdAtom = useSetAtom(featureFlagUserIdAtom);
  const dispatch = useDispatch();
  const [, setLocale] = useLocaleAtom();
  const [, setCustomerIdInSessionStorage] = useSessionStorage<string | null>(
    SESSION_STORAGE_CUSTOMER_ID_KEY,
    null
  );

  const customerQuery = useCustomerQuery();

  useEffect(() => {
    const customer = customerQuery.data;

    if (customer) {
      setCustomerIdInSessionStorage(customer.id);
      setLocale(customer.languagePreference);
      setFeatureFlagUserIdAtom(customer.featureFlagUserId);
    }
  }, [
    customerQuery.data,
    dispatch,
    setCustomerIdInSessionStorage,
    setFeatureFlagUserIdAtom,
    setLocale,
  ]);

  if (customerQuery.isPending) {
    return <RhCircularProgress />;
  }

  if (customerQuery.isError) {
    const { error } = customerQuery;

    if (error.status === HttpStatusCodes.HTTP_403_FORBIDDEN) {
      navigate(signOutPath());
    }

    return <Error500Page />;
  }

  const customer = customerQuery.data;

  return (
    <CustomerContext.Provider value={customer}>
      {children}
    </CustomerContext.Provider>
  );
};
