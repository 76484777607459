export const renewalSummaryFormTranslations = {
  tRenewalSummaryFormAddAPaymentMethod:
    "Add a payment method to continue with Auto Pay",
  tRenewalSummaryFormAutoPayDescription:
    "The easiest way to never miss a payment.",
  tRenewalSummaryFormAutoPayLabel: "Auto Pay",
  tRenewalSummaryFormBillingPreferences: "Billing Preferences",
  tRenewalSummaryFormConfirmAutopayDisabled:
    "Are you sure you want to turn off Auto Pay?",
  tRenewalSummaryFormConfirmPaperlessDisabled:
    "Are you sure you want to turn off Paperless?",
  tRenewalSummaryFormError:
    "We are having issues renewing your contract. Please try again",
  tRenewalSummaryFormNoDiscountMessage:
    "You'll miss out on your {discountAmount} monthly discount and the average price/kWh you pay for electricity will go up.",
  tRenewalSummaryFormNoPaymentMethodsAllowed:
    "Unable to collect new payment methods. Please contact Rhythm support.",
  tRenewalSummaryFormPaperless: "Paperless",
  tRenewalSummaryFormPaperlessDescription:
    "No paper. Good for the environment. Good for you.",
  tRenewalSummaryFormPaperlessLegalText:
    "I agree to receive my welcome packet, bills, contract documents, and contract-related notices (including my contract expiration notices and disconnect notices) via email. I understand I won't receive my bills via regular mail. I confirm the email address I provided is valid.",
  tRenewalSummaryFormPastDueMessage:
    "You will be charged your total past due amount of {pastDueAmount} today",
  tRenewalSummaryFormPaymentMethodAddedError:
    "There was an error adding your payment method. Please try again later",
  tRenewalSummaryFormPaymentMethodAddedSuccess:
    "Your payment method was added successfully.",
  tRenewalSummaryFormSelectPayment: "Select Payment Method",
  tRenewalSummaryFormSignup: "Sign up for Contract",
  tRenewalSummaryFormSubmitting: "Submitting...",
  tRenewalSummaryFormTerms:
    "By checking this box, you agree to establish Rhythm as your retail electric provider, and you agree to the <tos>Terms of Service</tos>, <yrac>Your Rights as a Customer</yrac>, and <efl>Electricity Facts Label</efl> documents associated with your plan. You also authorize Rhythm to switch, establish, or change your service.",
};
