export const PaymentMethodOptionsTranslations = {
  tPaymentMethodOptionsMakeDefaultMethod: "Make default payment method",
  tPaymentMethodOptionsRememberDefaultPaymentMethod:
    "Remember payment method and set as default",
  tPaymentMethodOptionsRememberDefaultPaymentMethodInfo:
    "Payment information will be stored and set as default unless \n <bold>Remember payment method and set as default</bold> is not selected.",
  tPaymentMethodOptionsRememberPaymentMethod: "Remember payment method",
  tPaymentMethodOptionsRememberPaymentMethodInfo:
    "Payment information will be stored unless \n <bold>Remember payment method</bold> is not selected.",
};
