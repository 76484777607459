import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledImgWrapper = styled.div`
  img {
    height: 11.5rem;
    width: 100%;
  }
`;

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  justify-content: center;
  padding: ${rhSpacingPx(1)};
  text-align: center;
`;

export const StyledLinkButton = styled(RhLinkButton)`
  padding: ${rhSpacingPx(2.5)} ${rhSpacingPx(10)};
`;
