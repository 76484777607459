import {
  BrandImageUrls,
  ImageUrlContainsBrand,
} from "@portal-shared/hooks/useBrandImageUrls/types";
import { TranslationKey } from "@portal-shared/hooks/useRhIntl";
import { RecProduct } from "@portal-shared/types/offerSnapshotTypes";

interface OfferCardDataFromRecProduct {
  icon: ImageUrlContainsBrand;
  id: string;
  textKey: TranslationKey;
}

export const getOfferCardDataFromRecProduct = (
  recProduct: RecProduct,
  brandImageUrls: BrandImageUrls
): OfferCardDataFromRecProduct => {
  switch (recProduct) {
    case RecProduct.Solar100: {
      return {
        icon: brandImageUrls.iconSolar,
        id: "solar100",
        textKey: "tOfferFeaturesSolar",
      };
    }
    case RecProduct.Chapman100: {
      return {
        icon: brandImageUrls.iconWind,
        id: "chapman100",
        textKey: "tOfferFeaturesChapman",
      };
    }
    case RecProduct.TXWind100: {
      return {
        icon: brandImageUrls.iconWind,
        id: "txWind100",
        textKey: "tOfferFeaturesWind",
      };
    }
    default: {
      return {
        icon: brandImageUrls.iconGreen,
        id: "anyRec",
        textKey: "tOfferFeaturesAnyRec",
      };
    }
  }
};
