import { appBrand } from "@common/services/getEnvApplicationSettings.service";

type ImageUrlSlugs =
  | "iconAutopay"
  | "iconCalculator"
  | "iconCalendar"
  | "iconCar"
  | "iconClock"
  | "iconGreen"
  | "iconLock"
  | "iconPhone"
  | "iconPrice"
  | "iconScale"
  | "iconShield"
  | "iconSolar"
  | "iconTag"
  | "iconThermostat"
  | "iconWind"
  | "pageOffersBackgroundImageLeft"
  | "pageOffersBackgroundImageRight"
  | "portalTechnologyCloud"
  | "portalThermostat";

export type ImageUrlContainsBrand =
  `/assets/images/${typeof appBrand}/${ImageUrlSlugs}.${string}`;

export type BrandImageUrls = {
  [K in ImageUrlSlugs]: ImageUrlContainsBrand;
};

export const RhythmBrandImageUrls = {
  iconAutopay: "/assets/images/rhythm/iconAutopay.svg",
  iconCalculator: "/assets/images/rhythm/iconCalculator.svg",
  iconCalendar: "/assets/images/rhythm/iconCalendar.svg",
  iconCar: "/assets/images/rhythm/iconCar.svg",
  iconClock: "/assets/images/rhythm/iconClock.svg",
  iconGreen: "/assets/images/rhythm/iconGreen.svg",
  iconLock: "/assets/images/rhythm/iconLock.svg",
  iconPhone: "/assets/images/rhythm/iconPhone.svg",
  iconPrice: "/assets/images/rhythm/iconPrice.svg",
  iconScale: "/assets/images/rhythm/iconScale.svg",
  iconShield: "/assets/images/rhythm/iconShield.svg",
  iconSolar: "/assets/images/rhythm/iconSolar.svg",
  iconTag: "/assets/images/rhythm/iconTag.svg",
  iconThermostat: "/assets/images/rhythm/iconThermostat.svg",
  iconWind: "/assets/images/rhythm/iconWind.svg",
  pageOffersBackgroundImageLeft:
    "/assets/images/rhythm/pageOffersBackgroundImageLeft.svg",
  pageOffersBackgroundImageRight:
    "/assets/images/rhythm/pageOffersBackgroundImageRight.svg",
  portalTechnologyCloud: "/assets/images/rhythm/portalTechnologyCloud.svg",
  portalThermostat: "/assets/images/rhythm/portalThermostat.svg",
} satisfies BrandImageUrls;

export const EnergyTexasBrandImageUrls = {
  iconAutopay: "/assets/images/energy-texas/iconAutopay.svg",
  iconCalculator: "/assets/images/energy-texas/iconCalculator.svg",
  iconCalendar: "/assets/images/energy-texas/iconCalendar.svg",
  iconCar: "/assets/images/energy-texas/iconCar.svg",
  iconClock: "/assets/images/energy-texas/iconClock.svg",
  iconGreen: "/assets/images/energy-texas/iconGreen.svg",
  iconLock: "/assets/images/energy-texas/iconLock.svg",
  iconPhone: "/assets/images/energy-texas/iconPhone.svg",
  iconPrice: "/assets/images/energy-texas/iconPrice.svg",
  iconScale: "/assets/images/energy-texas/iconScale.svg",
  iconShield: "/assets/images/energy-texas/iconShield.svg",
  iconSolar: "/assets/images/energy-texas/iconSolar.svg",
  iconTag: "/assets/images/energy-texas/iconTag.svg",
  iconThermostat: "/assets/images/energy-texas/iconThermostat.svg",
  iconWind: "/assets/images/energy-texas/iconWind.svg",
  pageOffersBackgroundImageLeft:
    "/assets/images/energy-texas/pageOffersBackgroundImageLeft.svg",
  pageOffersBackgroundImageRight:
    "/assets/images/energy-texas/pageOffersBackgroundImageRight.svg",
  portalTechnologyCloud:
    "/assets/images/energy-texas/portalTechnologyCloud.svg",
  portalThermostat: "/assets/images/energy-texas/portalThermostat.svg",
} satisfies Record<keyof typeof RhythmBrandImageUrls, ImageUrlContainsBrand>;
