import { ajax } from "@common/services/ajax";
import { LoginRequest } from "@portal-shared/types/authnTypes";

const BASE_URL = "/api/portal/authn";

export const authnApi = {
  login: (data: LoginRequest): Promise<void> => {
    return ajax.post(`${BASE_URL}/login`, data);
  },
  logout: (): Promise<void> => {
    return ajax.post(`${BASE_URL}/logout`, {});
  },
  refreshToken: (): Promise<void> => {
    return ajax.post(`${BASE_URL}/refresh-token`, {});
  },
};
