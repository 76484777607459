import { ImageUrlContainsBrand } from "@portal-shared/hooks/useBrandImageUrls/types";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { TranslationKey, useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { getOfferCardDataFromRecProduct } from "@portal-shared/services/getOfferCardDataFromRecProduct";
import { ReactNode } from "react";

export interface FormattedFeature {
  icon: ImageUrlContainsBrand;
  id: string;
  text: string | ReactNode;
}

export const useFormattedFeatures = (
  offerSnapshot: OfferSnapshot
): FormattedFeature[] => {
  const brandImageUrls = useBrandImageUrls();
  const { t: originalT, tJsx: originalTJsx } = useRhIntl();
  const {
    isAutoPayPaperlessDiscountOffer,
    isMonthToMonth,
    isSimpliSafeOffer,
    isSimpliSafeOutdoorOffer,
    isSolarBuybackPriceDifferential,
    isThermostatOffer,
    isTimeOfUse,
    isTimeOfUseGeneration,
    offerLabels,
    solarEligible,
    termMonths,
    isThermostatIncluded,
  } = offerSnapshot;

  /**
   * A wrapper function above `useRhIntl.t()` to include some fallback logic. \
   * If a translation for `OfferFeatures.termXX` is not found, falls back to `OfferFeatures.term.fallback`.
   */
  function tWrapper(...props: Partial<Parameters<typeof originalT>>) {
    const [id] = props;

    if (!id) {
      throw new Error(
        `[OfferFeatures.useFormattedFeatures]: Missing translation id: ${id}`
      );
    }

    const renderText = originalT(id);

    if (renderText === id) {
      // eslint-disable-next-line no-console
      console.error(
        `[OfferFeatures.useFormattedFeatures]: Translation missing for: ${id}`
      );

      if (!id.startsWith("OfferFeatures.term")) {
        return id;
      }

      // OfferFeatures.termXX -> OfferFeatures -> OfferFeatures.term.fallback
      const fallbackKey = id
        .split(".")
        .slice(0, -1)
        .join(".")
        .concat(".term.fallback");

      return originalT(fallbackKey as TranslationKey);
    }

    return renderText;
  }

  function tJsxWrapper(...props: Partial<Parameters<typeof originalTJsx>>) {
    const [id, ...rest] = props;

    if (!id) {
      throw new Error(
        `[OfferFeatures.useFormattedFeatures]: Missing translation id: ${id}`
      );
    }

    const renderText = originalTJsx(id, ...rest);

    if (renderText === id) {
      // eslint-disable-next-line no-console
      console.error(
        `[OfferFeatures.useFormattedFeatures]: Translation missing for: ${id}`
      );

      if (!id.startsWith("OfferFeatures.term")) {
        return id;
      }

      // OfferFeatures.termXX -> OfferFeatures -> OfferFeatures.term.fallback
      const fallbackKey = id
        .split(".")
        .slice(0, -1)
        .join(".")
        .concat(".term.fallback");

      return originalT(fallbackKey as TranslationKey);
    }

    return renderText;
  }

  const firstFeatureItem = (): FormattedFeature[] => {
    if ((isTimeOfUse || isTimeOfUseGeneration) && offerLabels.includes("EV")) {
      return [
        {
          icon: brandImageUrls.iconCar,
          id: "ev-tou",
          text: tWrapper("tOfferFeaturesEvTou"),
        },
      ];
    }

    if (isTimeOfUseGeneration) {
      return [
        {
          icon: brandImageUrls.iconPrice,
          id: "timeOfUseGenerationCredits",
          text: tWrapper("tOfferFeaturesTermTimeOfUseGenerationCredits"),
        },
      ];
    }
    if (isSolarBuybackPriceDifferential) {
      return [
        {
          icon: brandImageUrls.iconPrice,
          id: "solarBuybackPriceDifferential",
          text: tWrapper("tOfferFeaturesTermSolarBuybackPriceDifferential"),
        },
      ];
    }

    if (solarEligible) {
      return [
        {
          icon: brandImageUrls.iconPrice,
          id: "solarBuybackTerm",
          text: tWrapper("tOfferFeaturesTermSolarRooftopBuyback"),
        },
      ];
    }

    if (isMonthToMonth) {
      return [
        {
          icon: brandImageUrls.iconCalendar,
          id: "mtmTermLength",
          text: tWrapper("tOfferFeaturesTermMtm"),
        },
      ];
    }

    if (termMonths !== undefined) {
      return [
        {
          icon: brandImageUrls.iconCalendar,
          id: "xTermLength",
          text: tJsxWrapper("tOfferFeaturesTerm", { termMonths }) as string,
        },
      ];
    }

    return [];
  };

  const secondFeatureItem = (): FormattedFeature[] => {
    let rateDescription: FormattedFeature = isMonthToMonth
      ? {
          icon: brandImageUrls.iconTag,
          id: "variable",
          text: tWrapper("tOfferFeaturesVariable"),
        }
      : {
          icon: brandImageUrls.iconLock,
          id: "fixed",
          text: tWrapper("tOfferFeaturesFixed"),
        };

    if (solarEligible || isSolarBuybackPriceDifferential) {
      rateDescription = {
        icon: brandImageUrls.iconLock,
        id: "solarBuyback",
        text: tWrapper("tOfferFeaturesSolarBuybackRate"),
      };
    }

    if (isSimpliSafeOffer) {
      rateDescription = {
        icon: brandImageUrls.iconShield,
        id: "simplisafe",
        text: tWrapper("tOfferFeaturesSimplisafe"),
      };
    }

    if (isSimpliSafeOutdoorOffer) {
      rateDescription = {
        icon: brandImageUrls.iconShield,
        id: "simplisafeoutdoor",
        text: tWrapper("tOfferFeaturesSimplisafeOutdoor"),
      };
    }

    if (!isAutoPayPaperlessDiscountOffer && isThermostatOffer) {
      rateDescription = {
        icon: brandImageUrls.iconThermostat,
        id: "thermostat",
        text: tWrapper("tOfferFeaturesThermostat"),
      };
    }

    if (
      isAutoPayPaperlessDiscountOffer &&
      !(isTimeOfUseGeneration || isTimeOfUse)
    ) {
      rateDescription = {
        icon: brandImageUrls.iconAutopay,
        id: "autopayPaperlessDiscount",
        text: tWrapper("tOfferFeaturesAutopayPaperlessDiscountRequirements"),
      };
    }

    if ((isTimeOfUse || isTimeOfUseGeneration) && offerLabels.includes("EV")) {
      rateDescription = {
        icon: brandImageUrls.iconClock,
        id: "timeOfUse1",
        text: tWrapper("tOfferFeaturesTimeOfUse"),
      };
    }

    if (isTimeOfUse) {
      rateDescription = {
        icon: brandImageUrls.iconClock,
        id: "timeOfUse2",
        text: tWrapper("tOfferFeaturesTimeOfUse"),
      };
    }

    if (isTimeOfUseGeneration) {
      rateDescription = {
        icon: brandImageUrls.iconAutopay,
        id: "timeOfUseGenerationBuybackRate",
        text: tWrapper("tOfferFeaturesTimeOfUseGenerationBuybackRate"),
      };
    }

    return [rateDescription];
  };

  const thirdFeatureItem = (): FormattedFeature[] => {
    if (
      (isTimeOfUseGeneration || isTimeOfUse) &&
      isAutoPayPaperlessDiscountOffer
    ) {
      return [
        {
          icon: brandImageUrls.iconAutopay,
          // Different id from others to prevent multiple keys having same value
          id: "tou-autopay-discount-third-feature",
          text: tWrapper("tOfferFeaturesAutopayPaperlessDiscountRequirements"),
        },
      ];
    }

    if (isSimpliSafeOffer || isSimpliSafeOutdoorOffer) {
      return [
        {
          icon: brandImageUrls.iconGreen,
          // Different id from others to prevent multiple keys having same value
          id: "simplisafe-third-feature",
          text: tWrapper("tOfferFeaturesAnyRec"),
        },
      ];
    }

    if (isTimeOfUseGeneration) {
      return [
        {
          icon: brandImageUrls.iconClock,
          id: "timeDependent",
          text: tWrapper("tOfferFeaturesTimeOfUseGeneration"),
        },
      ];
    }

    if (isAutoPayPaperlessDiscountOffer && isThermostatOffer) {
      return [
        {
          icon: brandImageUrls.iconThermostat,
          id: "thermostat",
          text: tWrapper("tOfferFeaturesThermostat"),
        },
      ];
    }

    const productTypeInfo = getOfferCardDataFromRecProduct(
      offerSnapshot.recProduct,
      brandImageUrls
    );

    return [
      {
        ...productTypeInfo,
        text: tWrapper(productTypeInfo.textKey),
      },
    ];
  };

  if (isAutoPayPaperlessDiscountOffer && isTimeOfUse && isThermostatOffer) {
    return [
      {
        icon: brandImageUrls.iconCalendar,
        id: "xTermLength",
        text: tJsxWrapper("tOfferFeaturesTerm", { termMonths }) as string,
      },
      {
        icon: brandImageUrls.iconClock,
        id: "timeOfUse2",
        text: tWrapper("tOfferFeaturesTimeOfUse"),
      },
      {
        icon: brandImageUrls.iconAutopay,
        // Different id from others to prevent multiple keys having same value
        id: "tou-autopay-discount-third-feature",
        text: tWrapper("tOfferFeaturesAutopayPaperlessDiscountRequirements"),
      },
      {
        icon: brandImageUrls.iconThermostat,
        id: "thermostat",
        text: isThermostatIncluded
          ? tWrapper("tOfferFeaturesThermostatIncluded")
          : tWrapper("tOfferFeaturesThermostat"),
      },
    ];
  }

  return [...firstFeatureItem(), ...secondFeatureItem(), ...thirdFeatureItem()];
};
