import {
  OKTA_PASSWORD_REQUIREMENTS_ERROR,
  RhApiError,
} from "@common/types/errorTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useEnrollCookies } from "@enroll-utils/hooks/useEnrollCookies";
import { PortalPasswordField } from "@portal-account/components/PortalPasswordField/PortalPasswordField";
import { createPasswordPageTranslations } from "@portal-account/pages/CreatePasswordPage/CreatePasswordPage.en.i18n";
import { StyledCreatePasswordPageCaption } from "@portal-account/pages/CreatePasswordPage/CreatePasswordPage.styled";
import { analyticsEnableSendCustomerLoginEvent } from "@portal-account/slices/analyticsSlice";
import { LoggedOutFieldsLayout } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal-shared/components/LoggedOutForm/LoggedOutForm";
import { SESSION_STORAGE_CUSTOMER_KEY } from "@portal-shared/constants/customer.constant";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { portalAuthClient } from "@portal-shared/services/PortalAuthClient.service";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";

interface SignUpCreatePasswordFormValues {
  password: string;
}

interface CreatePasswordViaOktaFormProps {
  activationToken: string;
}

export const CreatePasswordViaOktaForm = (
  props: CreatePasswordViaOktaFormProps
) => {
  const { activationToken } = props;
  const [stateToken, setStateToken] = useState<string>("");
  const dispatch = useDispatch();
  const flash = useRhFlash();

  const { translate } = useTranslations();

  const {
    tCreatePasswordPageInvalidPassword,
    tCreatePasswordPagePasswordMinimumRequirements,
    tCreatePasswordPagePassword,
    tCreatePasswordPageCreatePassword,
    tCreatePasswordPageUnknownOktaIssue,
  } = translate(createPasswordPageTranslations);
  const { removeCookie } = useEnrollCookies();

  const createPassword = ({ password }: SignUpCreatePasswordFormValues) => {
    return portalAuthClient
      .createPassword({
        activationToken,
        password,
        stateToken,
      })
      .then(() => {
        dispatch(analyticsEnableSendCustomerLoginEvent());
        removeCookie("rhProspectUuid");
        sessionStorage.removeItem(SESSION_STORAGE_CUSTOMER_KEY);
      })
      .catch((error: RhApiError) => {
        setStateToken(error.data.stateToken || "");

        let errorMessage: string;

        switch (error.data.errorCode) {
          case OKTA_PASSWORD_REQUIREMENTS_ERROR: {
            errorMessage = tCreatePasswordPageInvalidPassword;
            break;
          }
          default: {
            errorMessage = tCreatePasswordPageUnknownOktaIssue;
          }
        }

        flash.error(errorMessage);

        return {
          password: [errorMessage],
        };
      });
  };

  return (
    <Form<SignUpCreatePasswordFormValues>
      onSubmit={createPassword}
      render={({ handleSubmit }) => (
        <LoggedOutForm
          onSubmit={handleSubmit}
          submitButtonText={tCreatePasswordPageCreatePassword}
          showProgress
        >
          <LoggedOutFieldsLayout dense>
            <PortalPasswordField name="password">
              {tCreatePasswordPagePassword}
            </PortalPasswordField>
            <StyledCreatePasswordPageCaption variant="caption" align="center">
              {tCreatePasswordPagePasswordMinimumRequirements}
            </StyledCreatePasswordPageCaption>
          </LoggedOutFieldsLayout>
        </LoggedOutForm>
      )}
    />
  );
};
