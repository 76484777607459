import { EMAIL_VALIDATION_REGEX } from "@common/constants/regex.constant";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as EyeForbiddenIcon } from "@design-system/icons/EyeForbiddenIcon.svg";
import { ReactComponent as EyeIcon } from "@design-system/icons/EyeIcon.svg";
import { useEnrollCookies } from "@enroll-utils/hooks/useEnrollCookies";
import { loginFormTranslations } from "@portal-account/components/LoginForm/LoginForm.en.i18n";
import {
  LoginFormCircularProgress,
  LoginFormCtaButton,
  LoginFormForm,
  LoginFormInput,
  LoginFormInputErrorMessage,
  LoginFormInputLabel,
  LoginFormPasswordContainer,
} from "@portal-account/components/LoginForm/LoginForm.styled";
import { SESSION_STORAGE_PREMISE_ID_KEY } from "@portal-account/components/PremiseIdChooser/PremiseIdChooser.constants";
import { analyticsEnableSendCustomerLoginEvent } from "@portal-account/slices/analyticsSlice";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useLoginMutation } from "@portal-shared/mutations/useLoginMutation";
import { customerHomePath } from "@portal-shared/routes/routePaths";
import React, { KeyboardEvent, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface SignInFormValues {
  email: string;
  password: string;
}

export const LoginForm = () => {
  const flash = useRhFlash();
  const navigate = useNavigate();
  const { translate } = useTranslations();
  const { removeCookie } = useEnrollCookies();
  const dispatch = useDispatch();
  const loginMutation = useLoginMutation();
  const eyeIconRef = useRef<SVGSVGElement | null>(null);
  const eyeIconForbiddenRef = useRef<SVGSVGElement | null>(null);

  const {
    handleSubmit,
    register,
    formState: { isSubmitted, errors },
  } = useForm<SignInFormValues>();

  const [inputPasswordType, setInputPasswordType] = useState<
    "text" | "password"
  >("password");

  const {
    tLoginFormEmail,
    tLoginFormErrorInvalidEmailOrPassword,
    tLoginFormErrorInvalidInputEmail,
    tLoginFormErrorInvalidInputEmailRequired,
    tLoginFormErrorInvalidInputPasswordRequired,
    tLoginFormHidePassword,
    tLoginFormLoggingInCustomer,
    tLoginFormPassword,
    tLoginFormShowPassword,
    tLoginFormSubmitCta,
  } = translate(loginFormTranslations);

  const onToggleViewPasswordClick = () => {
    setInputPasswordType((currentState) => {
      return currentState === "password" ? "text" : "password";
    });
  };

  const onToggleViewPasswordKeyDown = (event: KeyboardEvent) => {
    if (event.key === " " || event.key === "Enter") {
      event.stopPropagation();
      setInputPasswordType((currentState) => {
        if (currentState === "password") {
          requestAnimationFrame(() => {
            eyeIconRef.current?.focus();
          });
          return "text";
        }
        requestAnimationFrame(() => {
          eyeIconForbiddenRef.current?.focus();
        });
        return "password";
      });
    }
  };

  const onSubmit = handleSubmit(({ email, password }: SignInFormValues) => {
    if (errors.email || errors.password) {
      return;
    }

    loginMutation.mutate(
      { email, password },
      {
        onError: () => {
          flash.error(tLoginFormErrorInvalidEmailOrPassword);
        },
        onSuccess: () => {
          sessionStorage.removeItem(SESSION_STORAGE_PREMISE_ID_KEY);
          dispatch(analyticsEnableSendCustomerLoginEvent());
          removeCookie("rhProspectUuid");
          navigate(customerHomePath());
        },
      }
    );
  });

  const emailFormProps = register("email", {
    pattern: {
      message: tLoginFormErrorInvalidInputEmail,
      value: EMAIL_VALIDATION_REGEX,
    },
    required: tLoginFormErrorInvalidInputEmailRequired,
  });

  const passwordFormProps = register("password", {
    required: tLoginFormErrorInvalidInputPasswordRequired,
  });

  const ctaButtonDisabled = Boolean(
    isSubmitted && (errors.email || errors.password || loginMutation.isPending)
  );

  const eyeIconCommonProps = {
    onClick: onToggleViewPasswordClick,
    onKeyDown: onToggleViewPasswordKeyDown,
    tabIndex: 0,
  };

  return (
    <LoginFormForm onSubmit={onSubmit}>
      <LoginFormInputLabel htmlFor="email">
        {tLoginFormEmail}
      </LoginFormInputLabel>
      <LoginFormInput
        {...emailFormProps}
        id="email"
        $isError={Boolean(errors.email)}
      />
      <LoginFormInputErrorMessage>
        {errors.email && errors.email.message}
      </LoginFormInputErrorMessage>
      <LoginFormInputLabel htmlFor="password">
        {tLoginFormPassword}
      </LoginFormInputLabel>
      <LoginFormPasswordContainer>
        <LoginFormInput
          {...passwordFormProps}
          id="password"
          type={inputPasswordType}
          $isError={Boolean(errors.password)}
        />
        {inputPasswordType === "text" ? (
          <EyeIcon
            {...eyeIconCommonProps}
            ref={eyeIconRef}
            aria-label={tLoginFormHidePassword}
          />
        ) : (
          <EyeForbiddenIcon
            {...eyeIconCommonProps}
            ref={eyeIconForbiddenRef}
            aria-label={tLoginFormShowPassword}
          />
        )}
      </LoginFormPasswordContainer>
      <LoginFormInputErrorMessage>
        {errors.password && errors.password.message}
      </LoginFormInputErrorMessage>

      <LoginFormCtaButton
        data-tracking-click={{ event: "Customer attempting to sign in" }}
        type="submit"
        disabled={ctaButtonDisabled}
        color="primary"
      >
        {loginMutation.isPending ? (
          <>
            <LoginFormCircularProgress color="inherit" size={14} />
            <RhTypography color="inherit" variant="inherit">
              {tLoginFormLoggingInCustomer}
            </RhTypography>
          </>
        ) : (
          tLoginFormSubmitCta
        )}
      </LoginFormCtaButton>
    </LoginFormForm>
  );
};
