import { AppBrands } from "@common/enums/appBrands.enum";
import { appBrand } from "@common/services/getEnvApplicationSettings.service";
import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { RhTextButton } from "@design-system/components/RhTextButton/RhTextButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { borders } from "@design-system/constants/borders";
import { boxShadows } from "@design-system/constants/boxShadows";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

const cardPadding = "1.25rem";

export const CardCallout = styled.aside<{ $backgroundColor?: string }>`
  align-items: center;
  background-color: ${({ $backgroundColor }) => {
    if (appBrand === AppBrands.ENERGY_TEXAS) {
      return CSSVariables.COLOR_PRIMARY_MAIN;
    }

    return $backgroundColor ?? CSSVariables.COLOR_PRIMARY_MAIN;
  }};

  color: ${CSSVariables.COLOR_WHITE};
  display: flex;
  justify-content: flex-start;
  line-height: 1;
  margin-bottom: ${cardPadding};
  margin-left: -${cardPadding};
  margin-right: -${cardPadding};
  margin-top: -${cardPadding};
  padding: 0.25rem ${cardPadding};
  text-align: left;
`;

export const CardHeader = styled.header`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  gap: 0.25rem;
  padding-bottom: 0;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    padding-bottom: ${cardPadding};
  }
`;

export const CardTitle = styled(RhTypography)`
  &.MuiTypography-root {
    font-size: 18px;
  }
`;

export const CardDescription = styled(RhTypography)`
  display: none;

  &.MuiTypography-root {
    line-height: 1.2;
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    display: block;
  }
`;

export const CalculateBillButton = styled(RhTextButton)`
  align-items: center;
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(0.5)};
  justify-content: center;
  max-width: 10ch;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    align-items: flex-start;
    align-self: flex-start;
    justify-content: flex-start;
    justify-self: flex-start;
    text-align: left;
  }
`;

export const CompareRatesButton = styled(RhTextButton)`
  align-items: center;
  background: none;
  border: none;
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(0.5)};
  justify-content: center;

  &:hover {
    text-decoration: underline;
  }

  ${CalculateBillButton} + & {
    border-left: ${borders[100]};
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    border-left: 0;
    border-top: ${borders[100]};
    flex-direction: row;
    gap: ${rhSpacingPx(1)};
    grid-column: span 2;
    justify-self: center;
    padding: ${rhSpacingPx(1.5)} 0;
    width: 100%;

    ${CalculateBillButton} + & {
      border-left: none;
    }
  }
`;

export const CompareRatesText = styled(RhTypography)`
  &.MuiTypography-root {
    font-family: inherit;
    font-size: 10px;
    line-height: 1.1;
    max-width: 10ch;
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    &.MuiTypography-root {
      font-size: 14px;
      max-width: unset;
    }
  }
`;

export const CalculateBillText = styled(RhTypography)`
  &.MuiTypography-root {
    line-height: 1.1;
  }
`;

export const CardFooter = styled.footer``;

export const PhoneLink = styled.a`
  align-items: center;
  display: none;
  flex-direction: row;
  gap: ${rhSpacingPx(1)};
  justify-content: center;
  margin-top: ${rhSpacingPx(1.5)};
  white-space: nowrap;

  & > img {
    max-width: 24px;
  }

  &:hover {
    color: ${CSSVariables.COLOR_PRIMARY_MAIN} !important;
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    display: flex;
  }
`;

interface GetCalloutColorProps {
  $calloutBackgroundColor?: string;
  $hasCallout?: boolean;
  $hasPromo?: boolean;
}

const getCalloutCardBorder = ({
  $hasPromo,
  $hasCallout,
  $calloutBackgroundColor,
}: GetCalloutColorProps) => {
  if ($hasPromo) {
    return `1px solid ${CSSVariables.COLOR_SUCCESS_MAIN}`;
  }

  if ($hasCallout) {
    if (appBrand === AppBrands.ENERGY_TEXAS) {
      return `1px solid ${CSSVariables.COLOR_PRIMARY_MAIN}`;
    }

    return `1px solid ${$calloutBackgroundColor ?? CSSVariables.COLOR_PRIMARY_MAIN}`;
  }

  return `1px solid ${CSSVariables.COLOR_PRIMARY_MAIN}`;
};

export const Card = styled.article<GetCalloutColorProps>`
  background-color: ${CSSVariables.COLOR_WHITE};
  border: 1px solid ${CSSVariables.COLOR_WHITE};
  border-radius: 6px;
  box-shadow: ${boxShadows.default};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  padding: ${cardPadding};
  transition: border 100ms;
  width: 100%;
  z-index: 1;

  &:hover {
    border: ${({ $hasPromo, $hasCallout, $calloutBackgroundColor }) =>
      getCalloutCardBorder({
        $calloutBackgroundColor,
        $hasCallout,
        $hasPromo,
      })};
  }

  /* Why is this a css reset I don't know but we need to remove it  */
  & > * + * {
    margin-top: 0;
  }
`;

export const FooterActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rhSpacingPx(1)};
  padding-top: ${cardPadding};

  & > button {
    flex: 1;
  }
`;

export const Features = styled.div`
  display: none;
  padding: 0.5rem 0 0.25rem;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    display: block;
  }
`;

export const DesktopDivider = styled(RhDivider)`
  display: none;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    display: block;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rhSpacingPx(1)};
  justify-content: space-between;
  padding: ${rhSpacingPx(2)} 0;

  & > :first-child {
    flex: 1;
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    & > :first-child:has(+ ${CalculateBillButton}) {
      border-right: ${borders[100]};
    }

    & > :first-child:not(:has(+ ${CalculateBillButton})) {
      grid-column: span 2;
    }

    &:has(${CompareRatesButton}) {
      padding-bottom: 0;
    }
  }
`;
