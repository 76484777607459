import { usePortalSharedCookies } from "@portal-shared/hooks/usePortalSharedCookies";
import { useRefreshAccessTokenMutation } from "@portal-shared/mutations/useRefreshAccessTokenMutation";
import { signOutPath } from "@portal-shared/routes/routePaths";
import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface SessionGuardProps {}

export const SessionGuard = (props: PropsWithChildren<SessionGuardProps>) => {
  const { children } = props;
  const refreshAccessTokenMutation = useRefreshAccessTokenMutation();
  const navigate = useNavigate();
  const {
    cookies: { portal_session_state: portalSessionState },
    cookiesLoading,
  } = usePortalSharedCookies();

  const checkLogout = useCallback(() => {
    if (cookiesLoading) {
      return;
    }

    if (portalSessionState !== "logged_in") {
      navigate(signOutPath());
    }
  }, [cookiesLoading, portalSessionState]);

  const refreshToken = useCallback(() => {
    refreshAccessTokenMutation.mutate(undefined, {
      onError: () => {
        navigate("/sign-out");
      },
    });
  }, []);

  useEffect(() => {
    // Execute checkLogout immediately once
    checkLogout();

    const oneMinuteInMilliseconds = 60 * 1000;
    const checkLogoutIntervalId = setInterval(
      checkLogout,
      oneMinuteInMilliseconds
    );

    return () => clearInterval(checkLogoutIntervalId);
  }, [checkLogout]);

  useEffect(() => {
    // Execute refreshToken immediately once
    refreshToken();

    const fiftyFiveMinutesInMilliseconds = 55 * 60 * 1000;
    const refreshTokenIntervalId = setInterval(
      refreshToken,
      fiftyFiveMinutesInMilliseconds
    );

    return () => clearInterval(refreshTokenIntervalId);
  }, [refreshToken]);

  return <>{children}</>;
};
