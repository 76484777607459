import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { SessionCheckAuthn } from "@portal-shared/components/SessionCheckAuthn/SessionCheckAuthn";
import { SessionCheckOkta } from "@portal-shared/components/SessionCheckOkta/SessionCheckOkta";
import React, { PropsWithChildren } from "react";

interface SessionCheckProps {
  secured: boolean;
}

export const SessionCheck = (props: PropsWithChildren<SessionCheckProps>) => {
  const { children, secured } = props;
  const { featureFlagClient } = useFeatureFlagClient();
  const { portalAccountOktaBackendProxy } = featureFlagClient.getFlags([
    ["portalAccountOktaBackendProxy", "invalid"],
  ]);

  if (portalAccountOktaBackendProxy.value === "invalid") {
    return;
  }

  if (portalAccountOktaBackendProxy.value) {
    return <SessionCheckAuthn secured={secured}>{children}</SessionCheckAuthn>;
  }

  return <SessionCheckOkta secured={secured}>{children}</SessionCheckOkta>;
};
